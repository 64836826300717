export const globalMixin = {
    data() {
        return {

        }
    },
    methods: {
        erroFormatar(e) {
            return e.response.data.erro.split(';').join("<br />")
        },
        permissao(perm) {
            let usuarioSessao = JSON.parse(localStorage.getItem('comercialivaipora_gestor'))
            return usuarioSessao.permissao.filter((p) => {
                return p.idpermissao == perm
            }).length > 0
        },
        downloadBase64(res){
            const url = window.URL.createObjectURL(this.base64ToBlob(res.arquivo, res.type))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', res.arquivoname)
            document.body.appendChild(link)
            return link.click()
        },
        base64ToBlob(b64Data, contentType='', sliceSize= 512) {
            const byteCharacters = atob(b64Data)
            const byteArrays = []
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize)
                const byteNumbers = new Array(slice.length)
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i)
                }
                const byteArray = new Uint8Array(byteNumbers)
                byteArrays.push(byteArray)
            }
            const blob = new Blob(byteArrays, {type: contentType})
            return blob
        },
        formataDinheiro(v) {
            if (v == "" || v == null) {
                v = 0
            }
            return parseFloat(v).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        },
        formataData(v) {
            return v.split('T')[0].split('-').reverse().join('/')
        },
    }
};