let title = ' | Gestor - Comercial Ivaiporã'

const projeto = {
    path: '/projeto',
    props: true,
    component: () => import('@/Components/Projeto/ProjetoContainer'),
    children: [
        // {
        //     path: '',
        //     name: 'ProjetoLista',
        //     component: () => import('@/Components/Projeto/ProjetoLista'),
        //     meta: {
        //         title: 'Projetos' + title,
        //         permission: 'projeto',
        //         breadcrumb: [
        //             {
        //                 text: 'Início',
        //                 exact: true,
        //                 link: true,
        //                 disabled: false,
        //                 to: '/',
        //             },
        //             {
        //                 text: 'Projetos',
        //                 exact: true,
        //                 link: true,
        //                 disabled: true,
        //                 to: '',
        //             }
        //         ]
        //     }
        // },
        {
            path: '',
            name: 'ProjetoTarefas',
            component: () => import('@/Components/Projeto/ProjetoTarefas'),
            meta: {
                title: 'Tarefas' + title,
                permission: 'projeto',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    // {
                    //     text: 'Projetos',
                    //     exact: true,
                    //     link: true,
                    //     disabled: false,
                    //     to: '/projeto',
                    // },
                    {
                        text: 'Demandas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'novo',
            name: 'ProjetoNovo',
            component: () => import('@/Components/Projeto/ProjetoNovo'),
            meta: {
                title: 'Projetos' + title,
                permission: 'projeto',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Projetos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/projeto',
                    },
                    {
                        text: 'Novo projeto',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'get/:idprojeto',
            props: true,
            name: 'ProjetoGet',
            component: () => import('@/Components/Projeto/ProjetoGet'),
            meta: {
                title: 'Projeto' + title,
                permission: 'projeto',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Projetos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/projeto',
                    },
                    {
                        text: 'Projeto',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'notificacao',
            name: 'ProjetoNotificacoes',
            component: () => import('@/Components/Projeto/ProjetoNotificacoes'),
            meta: {
                title: 'Notificações' + title,
                permission: 'projeto',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Projetos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/projeto',
                    },
                    {
                        text: 'Notificações',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        }
    ]
}

export default projeto