let title = ' | Gestor - Comercial Ivaiporã'

const ferramenta = {
    path: '/ferramenta',
    props: true,
    component: () => import('@/Components/Ferramenta/FerramentaContainer'),
    children: [
        {
            path: '',
            name: 'Ferramentas',
            component: () => import('@/Components/Ferramenta/Ferramentas'),
            meta: {
                title: 'Ferramentas' + title,
                permission: 'ferramenta',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Ferramentas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/ferramenta',
                    }
                ]
            }
        },
    ]
}

export default ferramenta