let title = ' | Gestor - Comercial Ivaiporã'

const controleGaiola = {
    path: '/controle/gaiola',
    props: true,
    component: () => import('@/Components/ControleGaiola/ControleGaiolaContainer.vue'),
    children: [
        {
            path: '',
            name: 'ControleGaiola',
            component: () => import('@/Components/ControleGaiola/ControleGaiola'),
            meta: {
                title: 'Compras' + title,
                permission: 'controle_gaiola',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Controle de Gaiolas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/controle/gaiola',
                    }
                ]
            }
        },
        {
            path: 'dashboard',
            name: 'ControleEquipamentosDashboard',
            component: () => import('@/Components/ControleGaiola/ControleEquipamentosDashboard'),
            meta: {
                title: 'Compras' + title,
                permission: 'controle_gaiola',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Controle de Gaiolas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/controle/gaiola/dashboard',
                    }
                ]
            }
        },
        {
            path: 'retorno',
            name: 'ControleGaiolaRetorno',
            component: () => import('@/Components/ControleGaiola/ControleGaiolaRetorno'),
            meta: {
                title: 'Compras' + title,
                permission: 'controle_gaiola',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Controle de Gaiolas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/controle/gaiola/retorno',
                    }
                ]
            }
        },
    ]
}

export default controleGaiola