let title = ' | Gestor - Comercial Ivaiporã'

const aluguelEquipamento = {
    path: '/aluguel-equipamento',
    props: true,
    component: () => import('@/Components/AluguelEquipamento/AluguelEquipamentoContainer'),
    children: [
        {
            path: '',
            name: 'AluguelEquipamento',
            component: () => import('@/Components/AluguelEquipamento/AluguelEquipamento'),
            meta: {
                title: 'Aluguel de equipamento' + title,
                permission: 'aluguel_equipamento_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Aluguel de equipamentos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'faturar',
            name: 'AluguelEquipamentoFaturar',
            component: () => import('@/Components/AluguelEquipamento/AluguelEquipamentoFaturar'),
            meta: {
                title: 'Aluguel de equipamento' + title,
                permission: 'aluguel_equipamento_inserir',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Aluguel de equipamentos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/aluguel-equipamento',
                    },
                    {
                        text: 'Aluguel',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'get/:idpedido',
            props: true,
            name: 'AluguelEquipamentoGet',
            component: () => import('@/Components/AluguelEquipamento/AluguelEquipamentoGet'),
            meta: {
                title: 'Aluguel de equipamento' + title,
                permission: 'aluguel_equipamento_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Aluguel de equipamentos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/aluguel-equipamento',
                    },
                    {
                        text: 'Aluguel de equipamento',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    },
                ]
            }
        },
        {
            path: 'lista',
            name: 'AluguelEquipamentoLista',
            component: () => import('@/Components/AluguelEquipamento/AluguelEquipamentoLista'),
            meta: {
                title: 'Aluguel de equipamento' + title,
                permission: 'aluguel_equipamento_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Aluguel de equipamentos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/aluguel-equipamento',
                    },
                    {
                        text: 'Equipamentos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'lista/get/:idequipamento',
            props: true,
            name: 'AluguelEquipamentoListaGet',
            component: () => import('@/Components/AluguelEquipamento/AluguelEquipamentoListaGet'),
            meta: {
                title: 'Aluguel de equipamento' + title,
                permission: 'aluguel_equipamento_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Aluguel de equipamentos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/aluguel-equipamento',
                    },
                    {
                        text: 'Equipamentos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/aluguel-equipamento/lista',
                    },
                    {
                        text: 'Equipamento',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    },
                ]
            }
        },
    ]
}

export default aluguelEquipamento