let title = ' | Gestor - Comercial Ivaiporã'

const comissao = {
    path: '/comissao',
    props: true,
    component: () => import('@/Components/Comissao/ComissaoContainer'),
    children: [
        {
            path: 'simulador',
            name: 'ComissaoSimuladorSalario',
            component: () => import('@/Components/Comissao/ComissaoSimuladorSalario'),
            meta: {
                title: 'Simulador' + title,
                permission: 'comissao_vendas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Simulador',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/simulador',
                    }
                ]
            }
        },
        {
            path: '',
            name: 'ComissaoVendas',
            component: () => import('@/Components/Comissao/ComissaoVendas'),
            meta: {
                title: 'Comissão' + title,
                permission: 'comissao_vendas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Simulador',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/simulador',
                    },
                    {
                        text: 'Vendas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/comissao',
                    }
                ]
            }
        },
        {
            path: 'configuracao',
            name: 'ComissaoConfiguracao',
            component: () => import('@/Components/Comissao/ComissaoConfiguracao'),
            meta: {
                title: 'Lançamento' + title,
                permission: 'comissao_vendas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Simulador',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/simulador',
                    },
                    {
                        text: 'Lançamento',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/configuracao',
                    }
                ]
            }
        },
        {
            path: 'bi',
            name: 'ComissaoBI',
            component: () => import('@/Components/Comissao/ComissaoBI'),
            meta: {
                title: 'Dashboard BI' + title,
                permission: 'comissao_vendas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Simulador',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/simulador',
                    },
                    {
                        text: 'Dashboard BI',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/bi',
                    }
                ]
            }
        },
    ]
}

export default comissao