let title = ' | Gestor - Comercial Ivaiporã'

const entrega = {
    path: '/liberacao',
    props: true,
    component: () => import('@/Components/Liberacao/LiberacaoContainer'),
    children: [
        {
            path: '',
            name: 'LiberaCimentoAguardando',
            component: () => import('@/Components/Liberacao/LiberaClienteCimento.vue'),
            meta: {
                title: 'Libera' + title,
                permission: 'liberacao_submenu',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Liberação',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/liberacao',
                    }
                ]
            }
        }
    ]
}

export default entrega