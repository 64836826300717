let title = ' | Gestor - Comercial Ivaiporã'

const cliente = {
    path: '/cliente',
    props: true,
    component: () => import('@/Components/Cliente/ClienteContainer'),
    children: [
        {
            path: '',
            name: 'ClienteLista',
            component: () => import('@/Components/Cliente/ClienteLista'),
            meta: {
                title: 'Clientes' + title,
                permission: 'cliente_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Clientes',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/cliente',
                    }
                ]
            }
        },
        {
            path: 'dashboard/vendas',
            name: 'ClienteDashboardVendas',
            component: () => import('@/Components/Cliente/ClienteDashboardVendas'),
            meta: {
                title: 'Dashboard Vendas' + title,
                permission: 'cliente_dashboard_vendas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Clientes',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/cliente',
                    },
                    {
                        text: 'Dashboard Vendas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'get/:idcliente',
            props: true,
            name: 'ClienteGet',
            component: () => import('@/Components/Cliente/ClienteGet'),
            meta: {
                title: 'Cliente' + title,
                permission: 'cliente_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Clientes',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/cliente',
                    },
                    {
                        text: 'Cliente',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'novo',
            name: 'ClienteNovo',
            component: () => import('@/Components/Cliente/ClienteNovo'),
            meta: {
                title: 'Clientes' + title,
                permission: 'cliente_cadastrar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Clientes',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/cliente',
                    },
                    {
                        text: 'Cadastro',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'alterar/:idcliente',
            props: true,
            name: 'ClienteAlterar',
            component: () => import('@/Components/Cliente/ClienteAlterar'),
            meta: {
                title: 'Cliente' + title,
                permission: 'cliente_alterar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Clientes',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/cliente',
                    },
                    {
                        text: 'Alterar',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default cliente