let title = ' | Gestor - Comercial Ivaiporã'

const seguro = {
    path: '/seguro',
    props: true,
    component: () => import('@/Components/Seguro/SeguroContainer'),
    children: [
        {
            path: '',
            name: 'SeguroSortePremiada',
            component: () => import('@/Components/Seguro/SeguroSortePremiada'),
            meta: {
                title: 'Sorte Premiada' + title,
                permission: 'seguro_prestamista',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Sorte Premiada',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'prestamista',
            name: 'SeguroPrestamista',
            component: () => import('@/Components/Seguro/SeguroPrestamista'),
            meta: {
                title: 'Seguros' + title,
                permission: 'seguro_prestamista',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Seguro Prestamista',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'listar',
            name: 'SeguroLista',
            component: () => import('@/Components/Seguro/SeguroLista'),
            meta: {
                title: 'Seguros' + title,
                permission: 'seguro_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Seguros',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default seguro