let title = ' | Gestor - Comercial Ivaiporã'

const chamado = {
    path: '/chamado',
    props: true,
    component: () => import('@/Components/Chamado/ChamadoContainer'),
    children: [
        {
            path: '',
            name: 'ChamadoLista',
            component: () => import('@/Components/Chamado/ChamadoLista'),
            meta: {
                title: 'Chamados' + title,
                permission: 'chamado',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Meus chamados',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'recebido',
            name: 'ChamadoRecebidoLista',
            component: () => import('@/Components/Chamado/ChamadoRecebidoLista'),
            meta: {
                title: 'Chamados' + title,
                permission: 'chamado',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Chamados recebidos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'novo',
            name: 'ChamadoNovo',
            component: () => import('@/Components/Chamado/ChamadoNovo'),
            meta: {
                title: 'Chamados' + title,
                permission: 'chamado',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Meus chamados',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/chamado',
                    },
                    {
                        text: 'Novo chamado',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'notificacao',
            name: 'ChamadoNotificacoes',
            component: () => import('@/Components/Chamado/ChamadoNotificacoes'),
            meta: {
                title: 'Notificações' + title,
                permission: 'chamado',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Meus chamados',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/chamado',
                    },
                    {
                        text: 'Notificações',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'configuracao',
            name: 'ChamadoConfiguracao',
            component: () => import('@/Components/Chamado/ChamadoConfiguracao'),
            meta: {
                title: 'Configurações' + title,
                permission: 'chamado_configuracao',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Meus chamados',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/chamado',
                    },
                    {
                        text: 'Configurações',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'get/:idchamado',
            props: true,
            name: 'ChamadoGet',
            component: () => import('@/Components/Chamado/ChamadoGet'),
            meta: {
                title: 'Chamado' + title,
                permission: 'chamado',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Chamados',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/chamado',
                    },
                    {
                        text: 'Chamado',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'dashboard',
            name: 'ChamadoDashboard',
            component: () => import('@/Components/Chamado/ChamadoDashboard'),
            meta: {
                title: 'Chamados' + title,
                permission: 'chamado_dashboard',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Meus chamados',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/chamado',
                    },
                    {
                        text: 'Dashboard',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default chamado