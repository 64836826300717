let title = ' | Gestor - Comercial Ivaiporã'

const unificador = {
    path: '',
    props: true,
    component: () => import('@/Components/PedidoCompra/PedidoContainer.vue'),
    children: [
        {
            path: 'pedido_compra',
            name: 'PedidoCompra',
            component: () => import('@/Components/PedidoCompra/PedidoCompra.vue'),
            meta: {
                title: 'Compras' + title,
                permission: 'pedidos_compra',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Pedidos de Compra',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/pedido_compra',
                    }
                ]
            }
        },
    ]
}

export default unificador