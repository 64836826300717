let title = ' | Gestor - Comercial Ivaiporã'

const parcelamento = {
    path: '/parcelamento',
    props: true,
    component: () => import('@/Components/Parcelamento/ParcelamentoContainer'),
    children: [
        {
            path: '',
            name: 'ParcelamentoEmitir',
            component: () => import('@/Components/Parcelamento/ParcelamentoEmitir'),
            meta: {
                title: 'Parcelamentos' + title,
                permission: 'parcelamento_emitir',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Parcelamentos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'boleto',
            name: 'ParcelamentoBoleto',
            component: () => import('@/Components/Parcelamento/ParcelamentoBoleto'),
            meta: {
                title: 'Boletos' + title,
                permission: 'parcelamento_boleto',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Boletos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'liberacao',
            name: 'ParcelamentoLiberacao',
            component: () => import('@/Components/Parcelamento/ParcelamentoLiberacao'),
            meta: {
                title: 'Liberação' + title,
                permission: 'parcelamento_liberacao',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Liberação',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'remessa',
            name: 'ParcelamentoRemessa',
            component: () => import('@/Components/Parcelamento/ParcelamentoRemessa'),
            meta: {
                title: 'Remessa' + title,
                permission: 'parcelamento_remessa',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Remessa',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'carne',
            name: 'ParcelamentoCarne',
            component: () => import('@/Components/Parcelamento/ParcelamentoCarne'),
            meta: {
                title: 'Carnês' + title,
                permission: 'parcelamento_carne',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Carnês',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default parcelamento