<template>
	<v-container>
		<v-row justify="center">
			<v-col cols="12">
				<v-img
					style="margin: 0 auto;"
					max-height="100"
					max-width="300"
					src="../../assets/icons/Gestor_Logo_.svg"
				></v-img>
			</v-col>
			<v-col cols="12" sm="8" md="7" lg="6">
				<v-container class="text-xs-center">
					<v-card flat class="rounded-lg" :loading="carregando">
						<v-card-title primary-title>
							<h4>Login</h4>
						</v-card-title>
						<v-divider></v-divider>
						<v-form>
							<v-card-text>
								<v-text-field
									v-model="dados.usuario"
									name="usuario"
									@keydown.enter="logar()"
									label="Usuário"
                  autofocus
								/>
								<v-text-field
									v-model="dados.senha"
									name="senha"
									@keydown.enter="logar()"
									label="Senha"
									type="password"
									autocomplete="off"
								/>
								<v-alert
									v-if="erro"
									dismissible
									icon="mdi-alert-circle-outline"
									close-icon="mdi-close"
									type="error"
									class="mb-0"
								>{{erro}}</v-alert>
							</v-card-text>
							<v-card-actions class="pb-4 pr-4">
								<v-spacer />
								<v-btn
									:loading="carregando"
									color="primary"
									class="font-weight-bold"
									@click="logar()"
								>Entrar</v-btn>
							</v-card-actions>
						</v-form>
					</v-card>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
	name: "Login",
	data: () => ({
		carregando: false,
		erro: "",
		dados: {},
	}),
	computed: {
		...mapState(["backendUrl", "sessaoChave"]),
	},
	methods: {
		...mapMutations(["login"]),
		logar() {
			if (!this.dados.usuario)
				return (this.erro = "Campo de usuário é obrigatório");
			if (!this.dados.senha)
				return (this.erro = "Campo de senha é obrigatório");
			this.carregando = true;
			axios
				.post(`${this.backendUrl}login`, this.dados)
				.then((res) => {
					this.erro = null;
					this.carregando = false;
					this.dados = {};

					this.$store.commit("uLogar", { ...res.data });
					localStorage.setItem(
						this.sessaoChave,
						JSON.stringify(res.data)
					);

					this.erro = null;
					this.carregando = false;
					this.$router.push("/");
				})
				.catch((e) => {
					this.erro = this.erroFormatar(e);
					this.carregando = false;
				});
		},
	},
};
</script>

<style scoped>
</style>