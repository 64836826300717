let title = ' | Gestor - Comercial Ivaiporã'

const omnichat = {
    path: '/omnichat',
    props: true,
    component: () => import('@/Components/OmniChat/OmniChatContainer'),
    children: [
        {
            path: '',
            name: 'OmniChatClietes',
            component: () => import('@/Components/OmniChat/OmniChatClientes'),
            meta: {
                title: 'Contatos' + title,
                permission: 'central_contatos_menu',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Exportador de contatos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/omnichat',
                    }
                ]
            }
        },
    ]
}

export default omnichat