let title = ' | Gestor - Comercial Ivaiporã'

const remissao = {
    path: '/remissao',
    props: true,
    component: () => import('@/Components/Remissao/RemissaoContainer'),
    children: [
        {
            path: '',
            name: 'RemissaoLista',
            component: () => import('@/Components/Remissao/RemissaoLista'),
            meta: {
                title: 'Remissões' + title,
                permission: 'remissao_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Remissões',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/remissao',
                    }
                ]
            }
        },
    ]
}

export default remissao