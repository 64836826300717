let title = ' | Gestor - Comercial Ivaiporã'

const entregaDomicilio = {
    path: '/entrega-domicilio',
    props: true,
    component: () => import('@/Components/EntregaDomicilio/EntregaDomicilioContainer.vue'),
    children: [
        {
            path: '',
            name: 'EntregaDomicilioDashboard',
            component: () => import('@/Components/EntregaDomicilio/EntregaDomicilioDashboard.vue'),
            meta: {
                title: 'Logística' + title,
                permission: 'cd_menu',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Acompanhamento Logístico',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/entrega-domicilio',
                    }
                ]
            }
        },
    ]
}

export default entregaDomicilio