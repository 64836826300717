let title = ' | Gestor - Comercial Ivaiporã'

const entrega = {
    path: '/entrega',
    props: true,
    component: () => import('@/Components/Entrega/EntregaContainer'),
    children: [
        {
            path: '',
            name: 'EntregaLista',
            component: () => import('@/Components/Entrega/EntregaLista'),
            meta: {
                title: 'Entregas' + title,
                permission: 'entrega_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Entregas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/entrega',
                    }
                ]
            }
        },
        {
            path: 'get/:idsolicitacao',
            props: true,
            name: 'EntregaGet',
            component: () => import('@/Components/Entrega/EntregaGet'),
            meta: {
                title: 'Entrega' + title,
                permission: 'entrega_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Entregas',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/entrega',
                    },
                    {
                        text: 'Entrega',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        }
    ]
}

export default entrega