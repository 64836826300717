let title = ' | Gestor - Comercial Ivaiporã'

const contrato = {
    path: '/contrato',
    props: true,
    component: () => import('@/Components/Contrato/ContratoContainer'),
    children: [
        {
            path: '',
            name: 'ContratoLista',
            component: () => import('@/Components/Contrato/ContratoLista'),
            meta: {
                title: 'Contratos' + title,
                permission: 'contrato_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Contratos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/contrato',
                    }
                ]
            }
        },
        {
            path: 'get/:idcontrato',
            props: true,
            name: 'ContratoGet',
            component: () => import('@/Components/Contrato/ContratoGet'),
            meta: {
                title: 'Contrato' + title,
                permission: 'contrato_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Contratos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/contrato',
                    },
                    {
                        text: 'Contrato',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        }
    ]
}

export default contrato