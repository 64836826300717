<template>
    <v-dialog v-model="dialog" persistent scrollable>
        <v-card outlined max-width="575">
            <v-card-title class="text-h5">Alterar senha</v-card-title>
            <v-card-subtitle class="mt-0">Defina sua nova senha, ela será necessária em seus próximos acessos.</v-card-subtitle>
            <v-card-text>
                <v-divider></v-divider>
                <v-form>
                    <v-row>
                        <v-col cols="12" class="mt-8 mb-n8">
                            <v-text-field label="Senha" type="password" v-model="senha" required autocomplete="off"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Confirmar Senha" type="password" v-model="confirmarSenha" required @keydown.enter="redefinirSenha()" autocomplete="off"></v-text-field>
                        </v-col>
                        <v-col v-if="erro" cols="12">
                            <v-alert v-if="erro" dismissible icon="mdi-alert-circle-outline" close-icon="mdi-close" type="error" class="mb-0">{{erro}}</v-alert>
                        </v-col>
                    </v-row>
                </v-form>                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="redefinirSenha()">
                    Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    name : "LoginDialog",
    data : () => ({
        dialog         : true,
        senha          : '',
        confirmarSenha : '',
        erro           : ''
    }),
    methods : {
        redefinirSenha() {
            if (this.senha == this.confirmarSenha) {
                this.dialog = false
            } else {
                this.erro = 'A confirmação de senha não confere'
            }
        }
    }
}
</script>