let title = ' | Gestor - Comercial Ivaiporã'

const balanco = {
    path: '/balanco',
    props: true,
    component: () => import('@/Components/Balanco/BalancoContainer'),
    children: [
        {
            path: '',
            name: 'BalancoExportador',
            component: () => import('@/Components/Balanco/BalancoExportador'),
            meta: {
                title: 'Balanço' + title,
                permission: 'balanco_exportar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Balanço',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'importar',
            name: 'BalancoImportador',
            component: () => import('@/Components/Balanco/BalancoImportador'),
            meta: {
                title: 'Balanço' + title,
                permission: 'balanco_importador',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Balanço',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'listar',
            name: 'BalancoLista',
            component: () => import('@/Components/Balanco/BalancoLista'),
            meta: {
                title: 'Balanço' + title,
                permission: 'balanco_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Balanço',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default balanco