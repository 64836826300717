let title = ' | Gestor - Comercial Ivaiporã'

const compras = {
    path: '/compras',
    props: true,
    component: () => import('@/Components/Compras/ComprasContainer'),
    children: [

        {
            path: '',
            name: 'ComprasCadastraProduto',
            component: () => import('@/Components/Compras/ComprasCadastraProduto'),
            meta: {
                title: 'Compras' + title,
                permission: 'compras_cadastro_produtos',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Cadastro de Produtos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/compras',
                    }
                ]
            }
        },
        {
            path: 'produto',
            name: 'ComprasManutencaoPreco',
            component: () => import('@/Components/Compras/ComprasManutencaoPreco'),
            meta: {
                title: 'Compras' + title,
                permission: 'compras_manutencao_precos',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Manutenção de preços por produto',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/compras',
                    }
                ]
            }
        },
        {
            path: 'fornecedor',
            name: 'ComprasManutencaoPrecoFornecedor',
            component: () => import('@/Components/Compras/ComprasManutencaoPrecoFornecedor'),
            meta: {
                title: 'Compras' + title,
                permission: 'compras_manutencao_precos',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Manutenção de preços por fornecedor',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/compras/fornecedor',
                    }
                ]
            }
        },
        {
            path: 'fila/atualizacao',
            name: 'ComprasFilaAtualizacao',
            component: () => import('@/Components/Compras/ComprasFilaAtualizacao'),
            meta: {
                title: 'Compras' + title,
                permission: 'compras_manutencao_precos',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Fila de atualização',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/compras/fila/atualizacao',
                    }
                ]
            }
        },
    ]
}

export default compras