let title = ' | Gestor - Comercial Ivaiporã'

const venda = {
    path: '/venda',
    props: true,
    component: () => import('@/Components/Venda/VendaContainer'),
    children: [
        {
            path: '',
            name: 'VendaProduto',
            component: () => import('@/Components/Venda/VendaProduto'),
            meta: {
                title: 'Vendas' + title,
                permission: 'produto_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Vendas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/produto',
                    }
                ]
            }
        },
        {
            path: 'pedido/faturar',
            name: 'VendaPedidoFaturar',
            component: () => import('@/Components/Venda/VendaPedidoFaturar'),
            meta: {
                title: 'Pedido' + title,
                permission: 'pedido_inserir',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Vendas',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/venda',
                    },
                    {
                        text: 'Pedido',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'pedido',
            name: 'VendaPedidoLista',
            component: () => import('@/Components/Venda/VendaPedidoLista'),
            meta: {
                title: 'Vendas' + title,
                permission: 'pedido_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Vendas',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/venda',
                    },
                    {
                        text: 'Pedidos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'pedido/get/:idpedido',
            props: true,
            name: 'VendaPedidoGet',
            component: () => import('@/Components/Venda/VendaPedidoGet'),
            meta: {
                title: 'Pedido' + title,
                permission: 'pedido_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Vendas',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/venda',
                    },
                    {
                        text: 'Pedidos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/venda/pedido',
                    },
                    {
                        text: 'Pedido',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'orcamento',
            name: 'VendaOrcamentoLista',
            component: () => import('@/Components/Venda/VendaOrcamentoLista'),
            meta: {
                title: 'Orçamento' + title,
                permission: 'orcamento_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Vendas',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/venda',
                    },
                    {
                        text: 'Orçamentos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'orcamento/get/:idorcamento',
            props: true,
            name: 'VendaOrcamentoGet',
            component: () => import('@/Components/Venda/VendaOrcamentoGet'),
            meta: {
                title: 'Orçamento' + title,
                permission: 'orcamento_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Vendas',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/venda',
                    },
                    {
                        text: 'Orçamentos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/venda/orcamento',
                    },
                    {
                        text: 'Orçamento',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default venda