let title = ' | Gestor - Comercial Ivaiporã'

const marketing = {
    path: '/marketing',
    props: true,
    component: () => import('@/Components/Marketing/MarketingContainer'),
    children: [
        {
            path: '',
            name: 'MarketingGerarPdf',
            component: () => import('@/Components/Marketing/MarketingGerarPdf'),
            meta: {
                title: 'Marketing' + title,
                permission: 'marketing_pdf',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Gerador de PDF',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/produto',
                    }
                ]
            }
        },
    ]
}

export default marketing