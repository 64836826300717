let title = ' | Gestor - Comercial Ivaiporã'

const meuUsuario = {
    path: '/meu-usuario',
    props: true,
    component: () => import('@/Components/MeuUsuario/MeuUsuarioContainer'),
    children: [
        {
            path: '',
            name: 'MeuUsuario',
            component: () => import('@/Components/MeuUsuario/MeuUsuario'),
            meta: {
                title: 'Meu usuário' + title,
                permission: 'meu_usuario',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Meu usuário',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default meuUsuario