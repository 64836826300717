let title = ' | Gestor - Comercial Ivaiporã'

const concreto = {
    path: '/concreto',
    props: true,
    component: () => import('@/Components/Concreto/ConcretoContainer'),
    children: [
        {
            path: '',
            name: 'ConcretoProduto',
            component: () => import('@/Components/Concreto/ConcretoProduto'),
            meta: {
                title: 'Venda de Concreto' + title,
                permission: 'concreto_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Venda de Concreto',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/concreto',
                    }
                ]
            }
        },
        {
            path: 'faturar',
            name: 'ConcretoPedidoFaturar',
            component: () => import('@/Components/Concreto/ConcretoPedidoFaturar'),
            meta: {
                title: 'Pedido' + title,
                permission: 'concreto_inserir',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Venda de Concreto',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/concreto',
                    },
                    {
                        text: 'Pedido',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'agendamento',
            name: 'ConcretoAgendamento',
            component: () => import('@/Components/Concreto/ConcretoAgendamento'),
            meta: {
                title: 'Pedido' + title,
                permission: 'concreto_agendamento',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Agendamento de Concreto',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/concreto/agendamento',
                    }
                ]
            }
        },
        {
            path: 'pedido',
            name: 'ConcretoPedidoLista',
            component: () => import('@/Components/Concreto/ConcretoPedidoLista'),
            meta: {
                title: 'Pedido' + title,
                permission: 'concreto_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Pedidos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/concreto/pedido',
                    }
                ]
            }
        },
        {
            path: 'pedido/get/:idpedido',
            props: true,
            name: 'ConcretoPedidoGet',
            component: () => import('@/Components/Concreto/ConcretoPedidoGet'),
            meta: {
                title: 'Pedido' + title,
                permission: 'concreto_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Vendas',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/venda',
                    },
                    {
                        text: 'Pedidos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/concreto/pedido',
                    },
                    {
                        text: 'Pedido',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default concreto