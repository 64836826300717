import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
import {trim} from "core-js/internals/string-trim";

Vue.filter('formataTextoCurto', function (t) {
    if (!t) { return '' }
    let texto = t.substring(0, 20)
    return texto
})

Vue.filter('formataTextoPedido', function (t) {
    if (!t) { return '' }
    let texto = `${t.substring(0, 15)}...`
    return texto
})

Vue.filter('formataTextoPedidoConcreto', function (t) {
    if (!t) { return '' }
    let texto = `${t.substring(0, 30)}...`
    return texto
})

Vue.filter('formataTextoProdutoCd', function (t) {
    if (!t) { return '' }
    let texto = `${t.substring(0, 36)}...`
    return texto
})

Vue.filter('formataTextoNomeVendedores', function (t) {
    if (!t) { return '' }

    let textoLimpo = t.replace(/[^a-zA-ZÀ-ÿ\s]/g, '');

    let textoInicial = textoLimpo.substring(0, 16);

    if (trim(textoLimpo).length > 16) {
        let proximoEspaco = trim(textoLimpo).indexOf(' ', 16);

        if (proximoEspaco !== -1) {
            textoInicial = trim(textoLimpo).substring(0, proximoEspaco);
        } else {
            textoInicial = trim(textoLimpo)+'..'
        }
    } else {
        textoInicial = trim(textoLimpo)
    }


    return textoInicial
})

Vue.filter('formataTextoNomeVendedoresCurto', function (t) {
    if (!t) { return '' }

    let textoLimpo = t.replace(/[^a-zA-ZÀ-ÿ\s]/g, '');

    let textoInicial = textoLimpo.substring(0, 12);

    if (trim(textoLimpo).length > 12) {
        let proximoEspaco = trim(textoLimpo).indexOf(' ', 12);

        if (proximoEspaco !== -1) {
            textoInicial = trim(textoLimpo).substring(0, proximoEspaco);
        } else {
            textoInicial = trim(textoLimpo)
        }
    } else {
        textoInicial = trim(textoLimpo)
    }


    return textoInicial
})

Vue.filter('formataDinheiro', function (v) {
    if (v == "" || v == null) {
        v = 0
    }
    return parseFloat(v).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
})

Vue.filter('formataQtdProduto', function (v) {
    if (v == "" || v == null) {
        v = 0;
    }
    // Converte o valor para float e formata para o padrão brasileiro, com 3 casas decimais
    return parseFloat(v).toLocaleString('pt-br', { minimumFractionDigits: 3, maximumFractionDigits: 3 });
});


Vue.filter('formataDinheiroTabela', function (v) {
    if (v == "" || v == null) {
        v = 0
    }
    return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(v);
})

Vue.filter('formataData', function (v) {
    if (v) {
        return v.split('T')[0].split('-').reverse().join('/')
    } else {
        return v
    }
})

Vue.filter('formatDate', function (v) {
    if (v) {
        const [day, month, year] = v.split('-');
        return `${day}/${month}/${year}`;
    } else {
        return v;
    }
})

Vue.filter('diferencaDias', function (d) {
    let dataAtual = new Date()
    let dataContrato = new Date(d.split('/').reverse().join('-'))
    let diferenca = Math.abs(dataAtual.getTime() - dataContrato.getTime())
    let dias = Math.ceil(diferenca / (1000 * 60 * 60 * 24))
    return (dias - 1)
})

Vue.filter('diferencaDiasBar', function (d) {
    let dataAtual = new Date()
    let dataContrato = new Date(d.split('/').reverse().join('-'))
    let diferenca = Math.abs(dataAtual.getTime() - dataContrato.getTime())
    let dias = Math.ceil(diferenca / (1000 * 60 * 60 * 24))
    return (dias - 1) * 100 / 90
})

Vue.filter('colorBar', function (d) {
    let dataAtual = new Date()
    let dataContrato = new Date(d.split('/').reverse().join('-'))
    let diferenca = Math.abs(dataAtual.getTime() - dataContrato.getTime())
    let dias = Math.ceil(diferenca / (1000 * 60 * 60 * 24))
    if (((dias - 1) * 100 / 90) <= 50) {
        return 'green'
    } else if (((dias - 1) * 100 / 90) > 50 && ((dias - 1) * 100 / 90) < 80) {
        return 'amber'
    } else {
        return 'red'
    }
})

Vue.filter('formataCep', function (cep) {
    if (cep == "" || cep == undefined || cep == null) {
        return cep
    } else {
        return cep.substr(0, 5) + '-' + cep.substr(5, 3)
    }
})

Vue.filter('formataContato', function (c) {
    if (c == "" || c == undefined || c == null || c == 'sem contato') {
        return c
    } else {
        c = c.replaceAll(' ', '')
        if (c.length == 11) {
            return '(' + c.substr(0, 2) + ') ' + c.substr(2, 5) + '-' + c.substr(7)
        } else if (c.length == 10) {
            return '(' + c.substr(0, 2) + ') ' + c.substr(2, 4) + '-' + c.substr(6)
        } else if (c.length == 9) {
            return c.substr(0, 5) + '-' + c.substr(5)
        } else if (c.length == 8) {
            return c.substr(0, 4) + '-' + c.substr(4)
        } else {
            return c
        }
    }
})

Vue.filter('formataCpf', function (cpf) {
    if (cpf == "" || cpf == undefined || cpf == null) {
        return cpf
    } else {
        cpf = cpf.replaceAll(' ', '')
        if (cpf.length == 11) {
            return cpf.substr(0, 3) + '.' + cpf.substr(3, 3) + '.' + cpf.substr(6, 3) + '-' + cpf.substr(9, 2)
        } else if (cpf.length == 10) {
            return cpf.substr(0, 2) + '.' + cpf.substr(2, 3) + '.' + cpf.substr(5, 3) + '-' + cpf.substr(8, 2)
        } else {
            return cpf
        }
    }
})

Vue.filter('formataCnpj', function (cnpj) {
    if (cnpj == "" || cnpj == undefined || cnpj == null) {
        return cnpj
    } else {
        cnpj = cnpj.replaceAll(' ', '')
        if (cnpj.length == 14) {
            return cnpj.substr(0, 2) + '.' + cnpj.substr(2, 3) + '.' + cnpj.substr(5, 3) + '/' + cnpj.substr(8, 4) + '-' + cnpj.substr(12, 2)
        } else {
            return cnpj
        }
    }
})

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        backendUrl: `${process.env.VUE_APP_BACKEND_URL}:${process.env.VUE_APP_BACKEND_PORT}/`,
        sessaoChave: 'comercialivaipora_gestor',
        usuario: {},
        pgLimit: 20,
        timeLimit: 400,
        logado: false,
        primeiroAcesso: true // trocar pelo caminho json usuario
    },
    mutations: {
        uLogar(state, usuario) {
            state.usuario = usuario
            if (usuario) {
                axios.defaults.headers.common['token'] = usuario.token;
                state.logado = true;
            } else {
                delete axios.defaults.headers.common['token'];
                state.logado = false;
            }
        }
    },
    actions: {}
})