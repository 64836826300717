<template>
	<v-menu bottom min-width="200px" rounded :close-on-content-click="true" offset-y>
		<template v-slot:activator="{ on }">
			<v-btn class="hidden-md-and-down mx-3" icon v-on="on" @mouseenter="init">
				<v-badge :color="dados.total > 0 ? 'red darken-3' : ''" overlap dot>
					<v-avatar :color="cores[posicao]" size="48">
						<span style="color: #FFF;" class="font-weight-bold">{{usuario.nome.substr(0,1)}}</span>
					</v-avatar>
				</v-badge>
			</v-btn>
		</template>
		<v-card>
			<v-list-item-content class="justify-center">
				<div class="mx-auto text-center">
					<v-avatar :color="cores[posicao]" size="48" >
						<span style="color: #FFF;" class="font-weight-bold">{{usuario.nome.substr(0,1)}}</span>
					</v-avatar>
					<h3 class="mt-3 text-uppercase">{{ usuario.nome.split(' ')[0] }}</h3>
					<p class="mb-3 text-uppercase text-caption">{{ usuario.grupo }}</p>
					<v-divider class="my-3" />
					<v-btn text block to="/meu-usuario" exact tile>Meu usuário</v-btn>
					<v-btn
						v-if="permissao('liberacao_submenu')"
						text
						block
						to="/liberacao"
						exact
						tile
					>Liberações Cimento</v-btn>
					<v-btn v-if="permissao('projeto')" text block to="/projeto" exact tile>Desenvolvimento</v-btn>
					<v-btn v-if="this.permissao('chamado')" text block to="/chamado" exact tile>Chamados</v-btn>
					<v-btn v-if="this.permissao('chamado')" text block to="/chamado/notificacao" exact tile>
						<v-badge color="red darken-3" :content="dados.total" :value="dados.total">Notificações</v-badge>
					</v-btn>
					<v-btn v-if="permissao('ferramenta')" text block to="/ferramenta" exact tile>Ferramentas</v-btn>
					<v-divider class="my-3" />
					<v-btn depressed rounded class="font-weight-bold" color="primary" to="/login/sair">Sair</v-btn>
				</div>
			</v-list-item-content>
		</v-card>
	</v-menu>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";

export default {
	name: "Avatar",
	data: () => ({
		cores: [
			"accent",
			"red",
			"green",
			"blue",
			"orange",
			"purple",
			"indigo",
			"deep-purple",
		],
		posicao: 0,
		dados: { total: 0 },
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
	},
	methods: {
		...mapMutations(["uLogout"]),
		listar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/notificacao/listar`, {
					limit: 99999999,
					offset: 0,
					idusuario: parseInt(this.usuario.idusuario),
					lido: false,
				})
				.then((res) => {
					this.dados = res.data;
				})
				.catch(() => {
					this.dados = { total: 0 };
				});
		},
		init() {
			this.posicao = Math.floor(Math.random() * this.cores.length);
		},
	},
	created() {
		this.listar();
	},
	mounted() {
		var channel = this.$pusher.subscribe("my-channel");
		channel.bind(`notificacao-${this.usuario.idusuario}`, (data) => {
			this.dados.total = data;
		});
	},
	beforeDestroy() {
		this.$pusher.unsubscribe("my-channel");
	},
};
</script>