let title = ' | Gestor - Comercial Ivaiporã'

const departamentoPessoal = {
    path: '/departamento-pessoal',
    props: true,
    component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalContainer'),
    children: [
        {
            path: 'lista',
            name: 'DepartamentoPessoalLista',
            component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalLista'),
            meta: {
                title: 'Departamentos' + title,
                permission: 'departamento_pessoal_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Turnover',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal',
                    },
                    {
                        text: 'Departamentos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/departamento-pessoal/lista',
                    },
                ]
            }
        },
        {
            path: 'lista/get/:iddepartamento',
            props: true,
            name: 'DepartamentoPessoalGet',
            component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalGet'),
            meta: {
                title: 'Departamento' + title,
                permission: 'departamento_pessoal_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Turnover',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal',
                    },
                    {
                        text: 'Departamentos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal/lista',
                    },
                    {
                        text: 'Departamento',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'exportador-senior',
            props: true,
            name: 'DepartamentoPessoalExportadorSenior',
            component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalExportadorSenior'),
            meta: {
                title: 'Exportador Sênior' + title,
                permission: 'departamento_pessoal_exportador_senior',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Turnover',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal',
                    },
                    {
                        text: 'Exportador Sênior',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'importador-salarios',
            props: true,
            name: 'DepartamentoPessoalImportadorSalarios',
            component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalImportadorSalarios'),
            meta: {
                title: 'Importador de salários' + title,
                permission: 'departamento_pessoal_importador_senior',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Turnover',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal',
                    },
                    {
                        text: 'Importador de salários',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: '',
            props: true,
            name: 'DepartamentoPessoalDashboardTurnover',
            component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalDashboardTurnover'),
            meta: {
                title: 'Turnover' + title,
                permission: 'departamento_pessoal_dashboard_turnover',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Turnover',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal',
                    },
                ]
            }
        },
        {
            path: 'estatisticas',
            props: true,
            name: 'DepartamentoPessoalDashboardEstatisticas',
            component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalDashboardEstatisticas'),
            meta: {
                title: 'Turnover' + title,
                permission: 'departamento_pessoal_dashboard_estatisticas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Turnover',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal',
                    },
                    {
                        text: 'Estatísticas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'hora-extra',
            props: true,
            name: 'DepartamentoPessoalHoraExtra',
            component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalHoraExtra'),
            meta: {
                title: 'Hora extra' + title,
                permission: 'departamento_pessoal_dashboard_hora_extra',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Turnover',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal',
                    },
                    {
                        text: 'Hora Extra',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'quadro-funcionarios',
            props: true,
            name: 'DepartamentoPessoalQuadroFuncionarios',
            component: () => import('@/Components/DepartamentoPessoal/DepartamentoPessoalQuadroFuncionarios'),
            meta: {
                title: 'Quadro funcionários' + title,
                permission: 'departamento_pessoal_qtd_funcionarios',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Turnover',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/departamento-pessoal',
                    },
                    {
                        text: 'Quadro Funcionários',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        }
    ]
}

export default departamentoPessoal