import Vue from 'vue'
import Router from 'vue-router'

import aluguelEquipamento from './routes/aluguelEquipamento'
import analise from './routes/analise'
import balanco from './routes/balanco'
import gpp from './routes/gpp'
import chamado from './routes/chamado'
import cliente from './routes/cliente'
import cd from './routes/cd'
import comissao from './routes/comissao'
import compras from './routes/compras'
import concreto from './routes/concreto'
import contrato from './routes/contrato'
import controleGaiola from './routes/controleGaiola'
import credu from './routes/credu'
import dashboard from './routes/dashboard'
import departamentoPessoal from './routes/departamentoPessoal'
import entrega from './routes/entrega'
import entregaDomicilio from './routes/entregaDomicilio'
import etiqueta from './routes/etiqueta'
import ferramenta from './routes/ferramenta'
import filial from './routes/filial'
import marketing from './routes/marketing'
import meuUsuario from './routes/meuUsuario'
import omnichat from './routes/omniChat'
import liberacao from './routes/liberacao'
import pricing from './routes/pricing'
import projeto from './routes/projeto'
import parcelamento from './routes/parcelamento'
import remissao from './routes/remissao'
import seguro from './routes/seguro'
import servicocredito from './routes/servicocredito'
import sorteio from './routes/sorteio'
import ti from './routes/ti'
import unificador from './routes/unificador'
import usuario from './routes/usuario'
import venda from './routes/venda'
import pedidoCompra from './routes/pedidoCompra'

Vue.use(Router)

let title = ' | Gestor - Comercial Ivaiporã'

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'Login',
            props: true,
            component: () => import('../Components/Login/Login'),
            meta: {
                title: 'Login' + title
            }
        },
        {
            path: '/login/sair',
            name: 'LoginSair',
            props: true,
            component: () => import('../Components/Login/Sair'),
            meta: {
                title: 'Sair' + title
            }
        },
        {
            path: '/prototipo',
            name: 'Prototipo',
            props: true,
            component: () => import('../Components/Ti/Prototipo'),
            meta: {
                title: 'Protótipo' + title
            }
        },
        aluguelEquipamento,
        analise,
        balanco,
        chamado,
        cd,
        cliente,
        comissao,
        compras,
        concreto,
        contrato,
        controleGaiola,
        credu,
        dashboard,
        departamentoPessoal,
        entrega,
        entregaDomicilio,
        etiqueta,
        ferramenta,
        filial,
        gpp,
        marketing,
        meuUsuario,
        omnichat,
        liberacao,
        parcelamento,
        pricing,
        projeto,
        remissao,
        seguro,
        servicocredito,
        sorteio,
        ti,
        unificador,
        usuario,
        venda,
        pedidoCompra,
        {
            path: '*',
            component: () => import('../Components/Erro/404'),
        }
    ]
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title == title ? title.replace(' | ', '') : to.meta.title

    if (to.path != '/' && to.path != '/login' && to.path != '/prototipo' && to.path != '/login/sair') {
        let usuarioSessao = JSON.parse(localStorage.getItem('comercialivaipora_gestor'))
        let usuarioPerm = usuarioSessao.permissao.filter((p) => {
            return p.idpermissao == to.meta.permission
        }).length > 0
        if (usuarioPerm) {
            next()
        } else {
            next({ path: '/' })
        }
    } else {
        next()
    }
})

router.afterEach(() => { })

export default router