let title = ' | Gestor - Comercial Ivaiporã'

const ti = {
    path: '/ti',
    props: true,
    component: () => import('@/Components/Ti/TiContainer'),
    children: [
        {
            path: '',
            name: 'TiHome',
            component: () => import('@/Components/Ti/TiHome'),
            meta: {
                title: 'Home' + title,
                permission: 'ti_home',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/ti',
                    }
                ]
            }
        },
        {
            path: 'dicionario-senior',
            name: 'TiDicionarioSenior',
            component: () => import('@/Components/Ti/TiDicionarioSenior'),
            meta: {
                title: 'Dicionário Sênior' + title,
                permission: 'ti_dicionario_senior',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/ti',
                    },
                    {
                        text: 'Dicionário Sênior',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'notas-de-versao',
            name: 'TiNotasDeVersao',
            component: () => import('@/Components/Ti/TiNotasDeVersao'),
            meta: {
                title: 'Notas de versão' + title,
                permission: 'ti_notas_versao',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/ti',
                    },
                    {
                        text: 'Notas de versão',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'cronjob',
            name: 'TiCronjob',
            component: () => import('@/Components/Ti/TiCronjob'),
            meta: {
                title: 'Cronjob' + title,
                permission: 'ti_cronjob',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/ti',
                    },
                    {
                        text: 'Cronjob',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'cronjob/db2',
            name: 'TiCronDB2',
            component: () => import('@/Components/Ti/TiCronDB2'),
            meta: {
                title: 'Cron DB2 Comissão' + title,
                permission: 'ti_cronjob',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/ti',
                    },
                    {
                        text: 'Cron DB2 Comissão',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'ciss',
            name: 'TiBancoCiss',
            component: () => import('@/Components/Ti/TiBancoCiss'),
            meta: {
                title: 'Banco CISS' + title,
                permission: 'ti_banco_ciss',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/ti',
                    },
                    {
                        text: 'Banco CISS',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'calculos',
            name: 'TiCaclculos',
            component: () => import('@/Components/Ti/TiCalculos'),
            meta: {
                title: 'Cálculos' + title,
                permission: 'ti_calculos',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/ti',
                    },
                    {
                        text: 'Cálculos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'bi',
            name: 'PowerBI',
            component: () => import('@/Components/Ti/TiBI'),
            meta: {
                title: 'Power BI' + title,
                permission: 'ti_bi',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/ti',
                    },
                    {
                        text: 'Power BI',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'acertos',
            name: 'Acertos',
            component: () => import('@/Components/Ti/TiAcertos'),
            meta: {
                title: 'Acertos' + title,
                permission: 'ti_acertos',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Home',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/ti',
                    },
                    {
                        text: 'Acertos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default ti