let title = ' | Gestor - Comercial Ivaiporã'

const usuario = {
    path: '/usuario',
    props: true,
    component: () => import('@/Components/Usuario/UsuarioContainer'),
    children: [
        {
            path: '',
            name: 'UsuarioLista',
            component: () => import('@/Components/Usuario/UsuarioLista'),
            meta: {
                title: 'Usuários' + title,
                permission: 'usuario_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Usuários',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/usuario',
                    }
                ]
            }
        },
        {
            path: 'dashboard/vendas',
            name: 'UsuarioDashboardVendas',
            component: () => import('@/Components/Usuario/UsuarioDashboardVendas'),
            meta: {
                title: 'Dashboard Vendas' + title,
                permission: 'usuario_dashboard_vendas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Usuários',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/usuario',
                    },
                    {
                        text: 'Dashboard Vendas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'dashboard/solar',
            name: 'UsuarioDashboardSolar',
            component: () => import('@/Components/Usuario/UsuarioDashboardSolar'),
            meta: {
                title: 'Dashboard Solar' + title,
                permission: 'usuario_dashboard_solar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Usuários',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/usuario',
                    },
                    {
                        text: 'Dashboard Solar',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'dashboard/visitas',
            name: 'UsuarioDashboardVisitas',
            component: () => import('@/Components/Usuario/UsuarioDashboardVisitas'),
            meta: {
                title: 'Dashboard Visitas' + title,
                permission: 'usuario_dashboard_visitas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Usuários',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/usuario',
                    },
                    {
                        text: 'Dashboard Visitas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'grupo',
            name: 'UsuarioGrupoLista',
            component: () => import('@/Components/Usuario/UsuarioGrupoLista'),
            meta: {
                title: 'Grupos' + title,
                permission: 'grupo_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Usuários',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/usuario',
                    },
                    {
                        text: 'Grupos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'grupo/get/:idgrupo',
            props: true,
            name: 'UsuarioGrupoGet',
            component: () => import('@/Components/Usuario/UsuarioGrupoGet'),
            meta: {
                title: 'Grupo' + title,
                permission: 'grupo_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Usuários',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/usuario',
                    },
                    {
                        text: 'Grupos',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/usuario/grupo',
                    },
                    {
                        text: 'Grupo',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'get/:idusuario',
            props: true,
            name: 'UsuarioGet',
            component: () => import('@/Components/Usuario/UsuarioGet'),
            meta: {
                title: 'Usuário' + title,
                permission: 'usuario_get',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Usuários',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/usuario',
                    },
                    {
                        text: 'Usuário',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'cadastrar',
            name: 'UsuarioCadastro',
            component: () => import('@/Components/Usuario/UsuarioCadastro'),
            meta: {
                title: 'Cadastro' + title,
                permission: 'usuario_inserir',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Usuários',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/usuario',
                    },
                    {
                        text: 'Cadastro',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default usuario