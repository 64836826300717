let title = ' | Gestor - Comercial Ivaiporã'

const etiqueta = {
    path: '/etiqueta',
    props: true,
    component: () => import('@/Components/Etiquetas/EtiquetasContainer'),
    children: [
        {
            path: '',
            name: 'EtiquetasLojaGerarPdf',
            component: () => import('@/Components/Etiquetas/EtiquetasLojaGerarPdf.vue'),
            meta: {
                title: 'Etiquetas' + title,
                permission: 'etiquetas_loja',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Gerador de Etiquetas Loja',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/etiqueta',
                    }
                ]
            }
        },
        {
            path: 'deposito',
            name: 'EtiquetasDepositoGerarPdf',
            component: () => import('@/Components/Etiquetas/EtiquetasDepositoGerarPdf.vue'),
            meta: {
                title: 'Etiquetas' + title,
                permission: 'etiquetas_deposito',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Gerador de Etiquetas Loja',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/etiqueta',
                    },
                    {
                        text: 'Gerador de Etiquetas Depósito',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },{
            path: 'gestao',
            name: 'EtiquetasGestãoGondola',
            component: () => import('@/Components/Etiquetas/EtiquetasGestãoGondola.vue'),
            meta: {
                title: 'Gestão de Gôndolas' + title,
                permission: 'etiquetas_gestao_gondola',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Gestão de Gôndolas',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/etiqueta',
                    },
                    {
                        text: 'Gerador de Etiquetas Depósito',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default etiqueta