let title = ' | Gestor - Comercial Ivaiporã'

const credu = {
    path: '/credu',
    props: true,
    component: () => import('@/Components/Credu/CreduContainer'),
    children: [
        {
            path: '',
            name: 'CreduLista',
            component: () => import('@/Components/Credu/CreduLista'),
            meta: {
                title: 'Credu' + title,
                permission: 'credu_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Credu',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'lancamento',
            name: 'CreduLancamento',
            component: () => import('@/Components/Credu/CreduLancamento'),
            meta: {
                title: 'Lançamento' + title,
                permission: 'credu_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Lançamento',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
        {
            path: 'dashboard',
            name: 'CreduDashboard',
            component: () => import('@/Components/Credu/CreduDashboard'),
            meta: {
                title: 'Dashboard' + title,
                permission: 'credu_listar',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Dashboard',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default credu