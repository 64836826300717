import Vue from 'vue'
import router from './router/router'
import { globalMixin } from '@/globalMixin';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './axios';
import store from './store'
import VueMask from 'v-mask'
//const AUTH_ENDPOINT = 'https://pusher-auth.now.sh';
import VuePusher from 'vue-pusher';
import Pusher from 'pusher-js';
Pusher.logToConsole = true;

Vue.config.productionTip = false
Vue.mixin(globalMixin)
Vue.use(VueMask)
Vue.use(VuePusher, {
  api_key: '0812d0d0499ae82c7a50',
  options: {
    cluster: 'sa1',
    //forceTLS: true,
    //authEndpoint: `${AUTH_ENDPOINT}/auth`,
    //authTransport: 'jsonp'
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
