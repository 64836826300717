let title = ' | Gestor - Comercial Ivaiporã'

const analise = {
    path: '/analise',
    props: true,
    component: () => import('@/Components/Analise/AnaliseContainer'),
    children: [
        {
            path: '',
            name: 'AnaliseLiberacaoLimite',
            component: () => import('@/Components/Analise/AnaliseLiberacaoLimite'),
            meta: {
                title: 'Limite de Crédito' + title,
                permission: 'analise_liberacao_limite',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Limite de Crédito',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '',
                    }
                ]
            }
        },
    ]
}

export default analise