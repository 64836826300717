let title = ' | Gestor - Comercial Ivaiporã'

const servicocredito = {
    path: '/servicocredito',
    props: true,
    component: () => import('@/Components/ServicoCredito/ServicoCreditoContainer'),
    
    children: [
        {
            path: '',
            props: true,
            name: 'resumo',
            component: () => import('@/Components/ServicoCredito/DashboardServicos'),
            meta: {
                title: 'Serviços' + title,
                permission: 'servico_credito_dashboard',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Serviços',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/servicocredito',
                    }
                ]
            }
                
        },
        {
            path: 'metas',
            props: true,
            name: 'metas',
            
            component: () => import('@/Components/ServicoCredito/Metas'),
            meta: {
                title: 'Metas' + title,
                permission: 'servico_credito_metas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Metas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/servicocredito',
                    }
                ]
            }
        },
        {
            path: 'propostas',
            props: true,
            name: 'propostas',

            component: () => import('@/Components/ServicoCredito/Propostas'),
            meta: {
                title: 'Propostas' + title,
                permission: 'servico_credito_propostas',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Propostas',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/servicocredito',
                    }
                ]
            }
        },
        {
            path: 'servicos',
            props: true,
            name: 'servicos',
            component: () => import('@/Components/ServicoCredito/Servicos'),
            meta: {
                title: 'Serviços' + title,
                permission: 'servico_credito_servicos',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Servicos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/servicocredito',
                    }
                ]
            }
        }
            
    ]
        
}

export default servicocredito