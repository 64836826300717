let title = ' | Gestor - Comercial Ivaiporã'

const pricing = {
    path: '/pricing',
    props: true,
    component: () => import('@/Components/Pricing/PricingContainer.vue'),
    children: [
        {
            path: '',
            name: 'PricingBI',
            component: () => import('@/Components/Pricing/PricingBI.vue'),
            meta: {
                title: 'Compras' + title,
                permission: 'price_bi',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Cadastro de Produtos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/compras',
                    }
                ]
            }
        },
    ]
}

export default pricing