let title = ' | Gestor - Comercial Ivaiporã'

const unificador = {
    path: '',
    props: true,
    component: () => import('@/Components/Unificador/UnificadorContainer'),
    children: [
        {
            path: 'unificador',
            name: 'UnificadorPedidos',
            component: () => import('@/Components/Unificador/UnificadorPedido'),
            meta: {
                title: 'Compras' + title,
                permission: 'unificador_pedidos',
                breadcrumb: [
                    {
                        text: 'Início',
                        exact: true,
                        link: true,
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Unificador de pedidos',
                        exact: true,
                        link: true,
                        disabled: true,
                        to: '/unificador',
                    }
                ]
            }
        },
    ]
}

export default unificador